<template>
    <div class="v-report">
        <header class="main-head">
            <div class="container">
                <img src="@/assets/logo.svg" alt="logo" class="logo">
            </div>
        </header>
        <Spinner v-show="!isDataLoaded" />
        <div class="container" v-show="isDataLoaded">
            <div class="head">
                <div class="line top-line">
                    <Select
                        :items="customers"
                        placeholder="Pick a Customer Name"
                        :onSelect="selectCustomer"
                        :selectedCustomer="selectedCustomer"
                    />
                    <div class="select-wrap">
                        <span class="placeholder" v-show="!selectedContact">
                            Pick a Contact
                        </span>
                        <select class="select" v-model="selectedContact">
                            <option value=""></option>
                            <option
                                v-for="contact in filteredContacts"
                                :key="contact.id"
                                :value="contact.id"
                            >
                                {{ contact.name }}
                            </option>
                        </select>
                        <img src="@/assets/down-arrow.svg" alt="arrow-down" class="arrow">
                    </div>
                    <MultiSelect
                        :items="brands"
                        placeholder="Brand Name"
                        :selectedItems="selectedBrands"
                        :onSelect="selectBrand"
                        :deleteItem="removeBrand"
                    />
                </div>
                <div class="line middle-line">
                    <MultiSelect
                        :items="qualities"
                        placeholder="Quality"
                        :selectedItems="selectedQualities"
                        :onSelect="selectQuality"
                        :deleteItem="removeQuality"
                    />
                    <MultiSelect
                        :items="publishableSelect"
                        placeholder="Publishable"
                        :selectedItems="selectedPublishable"
                        :onSelect="selectPublishable"
                        :deleteItem="removePublishable"
                    />
                </div>
                <div class="line bottom-line">
                    <div class="inp-wrap">
                        <input
                            type="text"
                            class="inp search-inp"
                            placeholder="Type to search"
                            v-model="search"
                        >
                        <img src="@/assets/search.svg" alt="search">
                    </div>
                    <div class="btns-wrap">
                        <!-- <button :class="`head-btn prices-btn ${priceBtnState}`">
                            <span>reset prices</span>
                        </button> -->
                        <button :class="`head-btn prices-btn ${quotesBtnState}`" @click="spotQuotes">
                            <span v-show="!isShowQuotesSpinner">Spot Quote</span>
                            <BtnSpinner v-show="isShowQuotesSpinner" />
                        </button>
                        <button :class="`head-btn export-btn ${exportBtnState}`" @click="exportHandler(false)">
                            <!-- <span v-show="!isShowExportSpinner">Export</span> -->
                            <span v-show="!isShowExportSpinner">Export and update prices</span>
                            <BtnSpinner v-show="isShowExportSpinner" />
                        </button>
                    </div>
                </div>
            </div>
            <p class="not-selected-text" v-show="!selectedCustomer"> Please select a customer</p>
            <div class="table-wrap" v-show="selectedCustomer">
                <p class="action-info">
                    {{ itemsNumber }} records found. {{ selectedItems.length }} selected.
                </p>
                <div class="table-head">
                    <p
                        class="table-title sort w20"
                        @click="sortedBy.brand = !sortedBy.brand"
                    >
                        <label class="check-wrap">
                            <input type="checkbox" @change="selectAll">
                            <div class="castom-check">
                                <img src="@/assets/check.svg" alt="check">
                            </div>
                        </label>
                        <span>Brand Name</span>
                        <img
                            src="@/assets/arrow-down.svg"
                            alt="arrow-down"
                            :class="`sort-arrow ${sortedBy.brand ? 'active' : ''}`"
                        >
                    </p>
                    <p class="table-title w20">
                        <span>Printer Model</span>
                    </p>
                    <p class="table-title w5">
                        <span>DPI</span>
                    </p>
                    <p class="table-title w10">
                        <span>Quality</span>
                    </p>
                    <p class="table-title w15">
                        <span>Part Number</span>
                    </p>
                    <p class="table-title w15">
                        <span>OEM Ref</span>
                    </p>
                    <p class="table-title w10">
                        <span>MSRP</span>
                    </p>
                    <p class="table-title w10">
                        <span>Publishable</span>
                    </p>
                    <p class="table-title w13" v-show="isOriginalPrice">
                        <span>Original Price</span>
                    </p>
                    <p class="table-title w12">
                        <span>Your Price</span>
                    </p>
                </div>
                <div class="table">
                    <Spinner v-show="!isDataLoaded"/>
                    <Item
                        v-for="item in sortedItems"
                        :key="item.id"
                        :id="item.id"
                        :brandName="item.brand"
                        :printerModel="item.model"
                        :dpi="item.dpi"
                        :quality="item.quality"
                        :partNumber="item.partNumber"
                        :oem="item.oem"
                        :msrp="item.msrp"
                        :customerPrice="item.customerPrice"
                        :originalPrice="item.originalPrice"
                        :publishable="item.publishable"
                        :isChecked="!!selectedItems.find(product => product.id === item.id)"
                        :isShowOriginalPrice="isOriginalPrice"
                        :changePrice="changePrice"
                    />
                </div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import Item from "./components/Item"
import Spinner from "../../components/Spinner"
import BtnSpinner from "../../components/BtnSpinner"
import MultiSelect from "../../components/MultiSelect"
import Select from "../../components/Select"
import config from "../../config"
import XLSX from "xlsx-color"
import { saveAs } from 'file-saver'
import { nanoid } from "nanoid"

export default {
    data: function() {
        return {
            search: "",
            selectedCustomer: "",
            selectedBrand: "",
            selectedQuality: "",
            selectedContact: "",
            selectedBrands: [],
            selectedQualities: [],
            publishableSelect: [],
            selectedPublishable: [],
            customers: [],
            contacts: [],
            brands: [],
            qualities: [],
            // selectedItems: [],
            skus: [],
            customerPrices: [],
            products: [],
            // items: [],
            isDataLoaded: false,
            isShowQuotesSpinner: false,
            isShowExportSpinner: false,
            isShowSelectPopup: false,
            priceChangedItems: [],
            numberPages: 1,
            sortedBy: {
                brand: false
            },
            isOriginalPrice: false,
        }
    },
    components: {
        Item,
        Spinner,
        MultiSelect,
        BtnSpinner,
        Select,
    },
    async mounted() {
        let { customerId } = this.$route.params
        
        await this.$store.dispatch("loadItems")

        let { items } = this.$store.state
        let customers = await this.getCustomers()
        let brands = await this.getBrands()
        let contacts = await this.getContacts()

        let qualities = [...new Set(items.map(({ quality }) => quality))].filter(item => !!item)
        let publishable = [...new Set(items.map(({ publishable }) => publishable))].filter(item => !!item)

        this.customers = customers
        this.brands = brands
        this.contacts = contacts
        this.qualities = qualities.map(item => {
            return { id: nanoid(), text: item }
        })
        this.publishableSelect = publishable.map(item => {
            return { id: nanoid(), text: item }
        })
        if(customerId) {
            this.selectedCustomer = +customerId
            this.selectCustomer()
        }

        document.addEventListener("scroll", this.handleScroll)
        this.isDataLoaded = true
    },
    computed: {
        items() {
            return this.$store.state.items
        },
        selectedItems() {
            return this.$store.state.selectedItems
        },
        sortedItems() {
            return this.filteredItems.sort((a, b) => {
                let aIndex = this.selectedItems.findIndex(item => item.id === a.id)
                let bIndex = this.selectedItems.findIndex(item => item.id === b.id)

                if(aIndex || bIndex) {
                    if(aIndex > bIndex) { return -1 }
                    if(aIndex < bIndex) { return 1 }
                }

                if(this.sortedBy.brand) {
                    if(a.brand < b.brand) { return 1; }
                    if(a.brand > b.brand) { return -1; }
                } else {
                    if(a.brand < b.brand) { return -1; }
                    if(a.brand > b.brand) { return 1; }
                }
                return 0
            })
        },
        filteredContacts() {
            return this.contacts.filter(item => item.relatedCustomer === this.selectedCustomer)
        },
        isSelectedBrands() { return !!this.selectedBrands.length },
        isSelectedQualities() { return !!this.selectedQualities.length },
        isSelectedPublishable() { return !!this.selectedPublishable.length },
        isFiltered() {
            return this.isSelectedBrands || this.search || this.isSelectedQualities || this.isSelectedPublishable
        },
        // TODO: Rewrite
        // filteredItems() {
        //     let isSelectedBrands = !!this.selectedBrands.length
        //     let isselectedQualities = !!this.selectedQualities.length
        //     let isSelectedPublishable = !!this.selectedPublishable.length

        //     if(isSelectedBrands || this.search || isselectedQualities || isSelectedPublishable) {
        //         return this.items.filter(item => {
        //             let searchCondition = item.brand.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.model.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.dpi.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.quality.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.partNumber.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.oem.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.msrp.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.customerPrice.toString().toLowerCase().includes(this.search.toLowerCase())
        //                 || item.originalPrice.toString().toLowerCase().includes(this.search.toLowerCase())

        //             let brands = isSelectedBrands ? this.selectedBrands.includes(item.brand) : true
        //             let qualities = isselectedQualities ? this.selectedQualities.includes(item.quality) : true
        //             let publishable = isSelectedPublishable ? this.selectedPublishable.includes(item.publishable) : true

    
        //             return searchCondition && brands && qualities && publishable
        //         })
        //     }
            
        //     return this.activeItems
        // },
        filteredItems() {
            if(this.isFiltered) {
                return this.items.filter(item => {
                    let searchCondition = item.brand.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.model.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.dpi.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.quality.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.partNumber.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.oem.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.msrp.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.customerPrice.toString().toLowerCase().includes(this.search.toLowerCase())
                        || item.originalPrice.toString().toLowerCase().includes(this.search.toLowerCase())

                    let brands = this.isSelectedBrands ? this.selectedBrands.includes(item.brand) : true
                    let qualities = this.isselectedQualities ? this.selectedQualities.includes(item.quality) : true
                    let publishable = this.isSelectedPublishable ? this.selectedPublishable.includes(item.publishable) : true

                    return searchCondition && brands && qualities && publishable
                })
            }
            
            return this.activeItems
        },
        exportBtnState() {
            if(this.selectedItems.length > 0)
                return "active"

            return ""
        },
        priceBtnState() {
            if(this.priceChangedItems.length > 0)
                return "active"

            return ""
        },
        quotesBtnState() {
            if(this.selectedItems.length > 0 && this.selectedCustomer && this.selectedContact)
                return "active"

            return ""
        },
        activeItems() {
            return this.items.slice(0, 50 * this.numberPages)
        },
        itemsNumber() {
            if(this.selectedBrands.length || this.selectedQualities.length || this.selectedPublishable.length || this.search)
                return this.filteredItems.length
                
            return this.items.length
        },
    },
    methods: {
        async spotQuotes() {
            if(!this.quotesBtnState)
                return false

            this.isShowQuotesSpinner = true

            try {
                let customerName = this.customers.find(item => item.id === this.selectedCustomer).name
                //let contactName = this.contacts.find(item => item.id === this.selectedContact).name
                let date = new Date()
                let month = +date.getMonth() + 1 < 10 ? "0" + (+date.getMonth() + 1) : +date.getMonth() + 1
                let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                let year = date.getFullYear()
                let dateString = `${month}.${day}.${year}`

                let response = await fetch(config.APIPath + "/spotQuotes", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    },
                    body: JSON.stringify({
                        contact: this.selectedContact,
                        customer: this.selectedCustomer,
                        selectedItems: this.selectedItems,
                        fileName: `${customerName}-${dateString}-Quote.xlsx`,
                    })
                })

                response = await response.json()

                this.isShowQuotesSpinner = false

                this.exportHandler(true)

                this.$store.commit("notifications/add", {
                    id: nanoid(),
                    text: "Quote have been successfully created",
                    status: "success",
                })
            } catch (err) {
                console.error(err)
            }
        },
        // TODO: Can be rewritten
        // selectCustomer(item) {
        //     this.selectedCustomer = item.id
        // },
        selectQuality(selectedItems) {
            this.selectedQualities = selectedItems
        },
        removeQuality(selectedItems) {
            this.selectedQualities = selectedItems
        },
        selectBrand(selectedItems) {
            this.selectedBrands = selectedItems
        },
        removeBrand(selectedItems) {
            this.selectedBrands = selectedItems
        },
        selectPublishable(selectedItems) {
            this.selectedPublishable = selectedItems
        },
        removePublishable(selectedItems) {
            this.selectedPublishable = selectedItems
        },
        handleScroll(e) {
            let isBottomPage = document.body.scrollHeight < (window.scrollY + screen.height)

            if(isBottomPage)
                this.numberPages += 1
        },
        showSelectPopup(id, isChecked) {
            let { customerId } = this.$route.params

            if(customerId)
                this.$router.push(`/report/${customerId}/select/${id}`)
            else
                this.$router.push(`/report/select/${id}`)
        },
        selectAll(e) {
            let isChecked = e.currentTarget.checked
            let items = this.items

            if(this.isFiltered)
                items = this.filteredItems

            if(isChecked)
                this.$store.commit("selectAllItems", items)
            else
                this.$store.commit("unselectAllItems")
            
        },
        s2ab(s) { 
                var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
                var view = new Uint8Array(buf);  //create uint8array as viewer
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
                return buf;    
        },
        async exportHandler(isSpote) {
            if(!this.exportBtnState)
                return false
            
            /********************************************************************/
            
            let customerName = this.customers.find(item => item.id === this.selectedCustomer).name
            
            let date = new Date()
            let month = +date.getMonth() + 1 < 10 ? "0" + (+date.getMonth() + 1) : +date.getMonth() + 1
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            let year = date.getFullYear()
            let dateString = `${month}.${day}.${year}`

            let wb = XLSX.utils.book_new()
            wb.SheetNames.push("Products")

            let cellStyles = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "d6e3bc" } 
                },
                border: {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }

            let subCellStyles = {
                border: {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }

            let ws_data = [
                [
                    {
                        v: 'Brand Name',
                        s: cellStyles,
                    },
                    {
                        v: 'Printer Model',
                        s: cellStyles,
                    },
                    {
                        v: 'DPI',
                        s: cellStyles,
                    },
                    {
                        v: 'Quality',
                        s: cellStyles,
                    },
                    {
                        v: 'Part Number',
                        s: cellStyles,
                    },
                    {
                        v: 'OEM Ref',
                        s: cellStyles,
                    },
                    {
                        v: 'MSRP',
                        s: cellStyles,
                    },
                    {
                        v: 'Original Price',
                        s: cellStyles,
                    },
                    {
                        v: 'Your Price',
                        s: cellStyles,
                    },
                ]
            ]

            this.selectedItems.forEach(item => {
                ws_data.push([
                    {
                        v: item.brand,
                        s: subCellStyles,
                    },
                    {
                        v: item.model,
                        s: subCellStyles,
                    },
                    {
                        v: item.dpi,
                        s: subCellStyles,
                    },
                    {
                        v: item.quality,
                        s: subCellStyles,
                    },
                    {
                        v: item.partNumber,
                        s: subCellStyles,
                    },
                    {
                        v: item.oem,
                        s: subCellStyles,
                    },
                    {
                        v: item.msrp,
                        s: subCellStyles,
                    },
                    {
                        v: item.originalPrice,
                        s: subCellStyles,
                    },
                    {
                        v: item.customerPrice,
                        s: subCellStyles,
                    },
                ])
            })

            let ws = XLSX.utils.aoa_to_sheet(ws_data)
            wb.Sheets["Products"] = ws
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'})

            if(!isSpote) {
    
                let response = await fetch(config.APIPath + "/save-prices", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    },
                    body: JSON.stringify({
                        selectedItems: this.selectedItems,
                        customer: this.selectedCustomer,
                    })
                })

                saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), `${customerName}-${dateString}-Prices.xlsx`)
            } else {
                // let contactName = this.contacts.find(item => item.id === this.selectedContact).name
                saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), `${customerName}-${dateString}-Quote.xlsx`)

            }

            
            this.$store.commit("unselectAllItems")
            this.$store.commit("auth/signOut")

            /********************************************************************/
        },
        // TODO: Rewrite it
        async selectCustomer(customer) {
            if(customer)
                this.selectedCustomer = customer.id

            let oldItems = this.items
            let prices = await this.getCustomerPrices()
            let updatedItems = []
            let { customerId } = this.$route.params
            this.isDataLoaded = false

            await this.$store.commit("clearItems")
            await this.$store.commit("unselectAllItems")

            prices = prices
                .filter(item => item.relatedCustomer === this.selectedCustomer)
                .sort((a, b) => {
                    let aDate = new Date(b.dateCreated).getTime()
                    let bDate = new Date(a.dateCreated).getTime()

                    return bDate - aDate
                })
                
            this.selectedContact = ""

            if(customerId != this.selectedCustomer)
                this.$router.push(`/report/${this.selectedCustomer}`)

            if(prices.length > 0) {
                this.isOriginalPrice = true

                updatedItems = oldItems.map(item => {
                    let priceItem = prices.find(price => price.relatedProduct === item.id)
                    
                    if (priceItem) {
                        let itemPrice

                        if (item.originalPrice === priceItem.price) {
                            item.originalPrice = item.customerPrice
                        }

                        if (!priceItem.price && isNaN(item.originalPrice))
                            itemPrice = item.originalPrice
                        else if (!priceItem.price && !isNaN(item.originalPrice))
                            itemPrice = "$" + item.originalPrice
                        else
                            itemPrice = "$" + priceItem.price

                        item.customerPrice = itemPrice
                    } else {
                        item.customerPrice = item.originalPrice
                    }

                    return item
                })

                this.$store.commit("setItems", updatedItems)
            } else {
                this.isOriginalPrice = false
                this.$store.commit("setItems", oldItems)
            }

            this.isDataLoaded = true
        },
        async changePrice(id, newPrice) {
             try {

                let item = this.items.find(item => item.id === id)
                let selectedItem = this.selectedItems.find(item => item.id === id)

                if(item)
                    item.customerPrice = newPrice
                
                if(selectedItem)
                    selectedItem.customerPrice = newPrice

                this.isOriginalPrice = true
            } catch(err) {
                console.error(err)
            }
        },
        async getProducts() {
            try {
                let response = await fetch(config.APIPath + "/products", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    },
                })

                response = await response.json()

                return response.products
            } catch(err) {
                console.error(err)
                this.$store.commit("auth/signOut")
            }
        },
        async getCustomerPrices() {
            try {
                let response = await fetch(config.APIPath + "/customers/prices", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    },
                })

                response = await response.json()

                return response.prices
            } catch(err) {
                console.error(err)
                this.$store.commit("auth/signOut")
            }
        },
        async getCustomers() {
            try {
                let response = await fetch(config.APIPath + "/customers", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    }
                })

                response = await response.json()

                return response.customers
            } catch(err) {
                console.error(err)
                this.$store.commit("auth/signOut")
            }
        },
        async getContacts() {
            try {
                let response = await fetch(config.APIPath + "/contacts", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    }
                })

                response = await response.json()

                return response.contacts
            } catch(err) {
                console.error(err)
                this.$store.commit("auth/signOut")
            }
        },
        async getBrands() {
            try {

                let response = await fetch(config.APIPath + "/brands", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    }
                })

                response = await response.json()

                return response.brands
            } catch(err) {
                console.error(err)
                this.$store.commit("auth/signOut")
            }
        },
    }
}
</script>

<style lang="sass">

div.v-report
    padding-bottom: 50px

    header.main-head
        background: #000
        margin-bottom: 36px
        padding: 8px

        div.container
            display: flex
            align-items: center
            justify-content: space-between

        div.left,
        div.right
            display: flex
            align-items: center

        img.logo
            margin-right: 34px

        div.contact
            display: flex
            align-items: center
            
            a
                font-size: 16px
                line-height: 26px
                color: #fff
                text-decoration: none
                font-weight: 700

            &:last-child
              margin-bottom: 0  

            img
                margin-right: 10px

    div.head
        margin-bottom: 30px

        div.line
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 24px

            &.middle-line,
            &.top-line
                justify-content: flex-start

                div.c-multi-select:first-child,
                div.c-select-wrap
                    margin-right: 15px

        div.btns-wrap
            display: flex
            align-items: center

        div.select-wrap
            position: relative
            
            span.placeholder
                position: absolute
                top: 50%
                left: 15px
                transform: translateY(-50%)
                letter-spacing: 0.004em
                font-size: 16px
                line-height: 24px
                color: #939593

            img
                position: absolute
                top: 50%
                right: 19px
                transform: translateY(-50%)
                max-width: 10px
            
        select.select
            min-height: 48px
            padding: 12px 16px
            font-size: 16px
            line-height: 24px
            border: 1px solid #939593
            border-radius: 6px
            background: #fff
            width: 390px
            outline: none
            -webkit-appearance: none
            -moz-appearance: none
            text-indent: 1px
            text-overflow: ''

        p.text
            margin: 0
            font-size: 14px
            line-height: 26px

        div.inp-wrap
            position: relative
            
            img
                position: absolute
                top: 50%
                right: 10px
                transform: translateY(-50%)
            
        input.search-inp
            min-height: 48px
            min-width: 390px
            padding: 12px 16px
            font-size: 16px
            line-height: 24px
            border: 1px solid #939593
            border-radius: 6px
            outline: none

        button.head-btn
            outline: none
            border: none
            color: #fff
            font-weight: 600
            font-size: 16px
            line-height: 26px
            text-transform: uppercase
            background: #B9BEC0
            min-width: 180px
            min-height: 48px
            padding: 5px 15px
            display: flex
            justify-content: center
            align-items: center
            border-radius: 6px
            transition: 0.2s
            margin-left: 10px

        button.export-btn
            &.active
                background: #09563B
                cursor: pointer

        button.prices-btn
            &.active
                background: #D79B44
                cursor: pointer

    div.table-head
        display: flex
        justify-content: space-between

        p.table-title
            display: flex
            align-items: center
            padding: 15px 10px
            margin: 0
            font-weight: 600

            img.sort-arrow
                max-width: 12px
                margin-left: 7px
                transition: 0.2s
                &.active
                    transform: rotate(180deg)

            &.sort
                cursor: pointer

    p.not-selected-text
        margin-top: 100px
        font-size: 20px
        font-weight: 500
        text-align: center

    select.castomer-select
        width: 225px
        padding: 5px 10px
        outline: none
        min-height: 32px
        margin-right: 15px

    .w3
        width: 3%

    .w5
        width: 5%

    .w10
        width: 10%

    .w12
        width: 12%

    .w13
        width: 13%

    .w15
        width: 15%

    .w20
        width: 20%

    .w30
        width: 30%

    .fade-enter-active, .fade-leave-active
        transition: opacity .3s

    .fade-enter, .fade-leave-to
        opacity: 0

</style>