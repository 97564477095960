<template>
    <div class="c-popup-wrap">
        <div class="create-product-popup">
            <div class="head">
                <img
                    src="@/assets/close.svg"
                    alt="close"
                    class="close"
                    @click="$store.commit('popups/hide', 'createProduct')"
                >
            </div>
            <div class="inp-group">
                <input
                    type="text"
                    class="inp"
                    placeholder="Part Number"
                    v-model="partNumber"
                >
                <!-- <input
                    type="text"
                    class="inp"
                    placeholder="OEM Ref"
                    v-model="oem"
                > -->
                <div class="inp-wrap">
                    <input
                        type="text"
                        class="inp"
                        placeholder="Brand"
                        v-model="brand"
                        @keypress="isShowBrandList = true"
                    >
                    <div class="prompt-list" v-show="brand && isShowBrandList">
                        <div
                            class="list-item"
                            v-for="item in filteredBrands"
                            :key="item.id"
                            @click="selectBrand(item)"
                        >
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>

                <p class="error" v-show="error">{{ error }}</p>
            </div>
            <div class="btn-wrap" @click="handleSubmit">
                <div class="btn">
                    <span>Save</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "../config"
import { nanoid } from "nanoid"

export default {
    data: function() {
        return {
            partNumber: "",
            //oem: "",
            brand: "",
            brandId: "",
            partNumbers: [],
            brands: [],
            isShowBrandList: true,
        }
    },
    async mounted() {
        try {
            let response = await fetch(config.APIPath + "/create-product-data")
            response = await response.json()

            this.partNumbers = response.partNumbers
            this.brands = response.brands

            console.log(response)
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        error() {
            if(this.partNumbers.includes(this.partNumber))
                return "This part number already exists"
        },
        filteredBrands() {
            return this.brands.filter(item => item.name.toLowerCase().includes(this.brand.toLowerCase()))
        },
    },
    methods: {
        async handleSubmit() {
            try {
                if(this.error)
                    return false

                let { rid } = this.$route.params

                let response = await fetch(config.APIPath + `/create-product/${rid}`, {
                    method: "POST",
                    body: JSON.stringify({
                        partNumber: this.partNumber,
                        //oem: this.oem,
                        brand: this.brandId,
                    })
                })
                
                this.partNumber = ""
                //this.oem = ""
                this.brand = ""
                this.brandId = ""

                response = await response.json()
                
                this.$store.commit("notifications/add", {
                    id: nanoid(),
                    text: "Product was created and synced",
                    status: "success",
                })

                this.$store.commit("popups/hide", "createProduct")
            } catch (err) {
                console.error(err)
            }
        },
        selectBrand(item) {
            this.brand = item.name
            this.brandId = item.id
            this.isShowBrandList = false
        }
    },
}

</script>

<style lang="sass">

div.c-popup-wrap
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100%
    background: rgba(0,0,0,.6)
    z-index: 100

    div.create-product-popup
        width: 360px
        padding: 25px
        border-radius: 5px
        background: #fff

    div.head
        display: flex
        justify-content: flex-end
        margin-bottom: 20px

    img.close
        max-width: 15px
        cursor: pointer

    div.inp-group
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        
        input.inp
            padding: 10px 15px
            font-size: 16px
            outline: none
            border: none
            border: 1px solid #bababa
            border-radius: 3px
            width: 49%
            margin-bottom: 15px
            width: 100%
            &:last-child
                margin-bottom: 0
    
    div.inp-wrap
        position: relative
        width: 100%

        input.inp
            margin-bottom: 0
        
        div.prompt-list
            position: absolute
            width: 100%
            top: 100%
            left: 0
            background: #fff

        div.list-item
            cursor: pointer
            padding: 10px 15px
            border-bottom: 1px solid #bababa
            transition: 0.1s
            &:hover
                background: #eee


    p.error
        color: red
        font-size: 13px
        margin: 0

    div.btn-wrap
        display: flex
        justify-content: center
        align-items: center
        margin-top: 15px
        
        div.btn
            background: green
            border-radius: 3px
            cursor: pointer
            display: flex
            justify-content: center
            align-items: center
            color: #fff
            text-transform: uppercase
            min-height: 40px
            min-width: 100px

</style>
