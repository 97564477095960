import { nanoid } from "nanoid"
import router from "../../router"
import config from "../../config"

export default {
    namespaced: true,
    state: () => ({
        userData: {
            email: "",
        },
        accessToken: "",
    }),
    getters: {
        isLogged(state) {
            return !!state.accessToken
        },
    },
    mutations: {
        setUserData(state, data) {
            state.userData = data
            if(localStorage)
                localStorage.setItem("data", JSON.stringify(data))
        },
        setToken(state, token) {
            state.accessToken = token
            if(localStorage)
                localStorage.setItem("ticket", token)
        },
        signOut(state) {
            state.userData = false
            state.accessToken = ""
            localStorage.removeItem("data")
            localStorage.removeItem("access_token")
            localStorage.removeItem("ticket")
            router.push("/")
        }
    },
    actions: {
        async signIn({ commit }, { email, password, customerId}) {
            try {
                let response = await fetch(config.APIPath + "/auth", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, password })
                })

                if(response.status === 400)
                    throw new Error("Incorrect login or password")

                response = await response.json()

                commit("setUserData", { email })
                commit("setToken", response.ticket)
                
                if(customerId)
                    router.push(`/report/${customerId}`)
                else
                    router.push(`/report`)
            } catch (err) {
                console.error("Error:", err)
                commit("notifications/add", {
                    id: nanoid(),
                    status: "error",
                    text: err.message,
                }, { root: true })
            }
        },
        // async signOut({ commit }) {
        //     commit("cle")
        // },
    }
}