<template>
    <div class="v-masters">
        <div class="btn-wrap">
            <div class="btn" @click="$store.commit('popups/show', 'createProduct')">
                <span>Create Product</span>
            </div>
        </div>
        <div class="select-wrap">
            <div class="value-wrap" @click.stop="handleClick">
                <p class="title">Find Product</p>
                <!-- <span class="placeholder" v-show="!selectedValue">Find Product</span>
                <div class="value" v-show="!!selectedValue">
                    <div class="item-value-wrap">
                        <p class="value-title">Purt Number</p>
                        <p class="value">{{ selectedValue.partNumber }}</p>
                    </div>
                    <div class="item-value-wrap">
                        <p class="value-title">Record ID#</p>
                        <p class="value">{{ selectedValue.id }}</p>
                    </div> -->
                    <!-- <span>{{ selectedValue }}</span> -->
                <!-- </div>
                <img src="@/assets/down-arrow.svg" alt="arrow-down" class="arrow"> -->
                <div class="search-inp-wrap">
                    <input type="text" class="search-inp" v-model="searchValue">
                </div>
            </div>
            <div class="body" v-show="isShowBody" @click.stop>
                <ul class="list">
                    <li
                        v-for="item in filteredItems"
                        :key="item.id"
                        class="list-item"
                        @click="selectItem(item)"
                    >
                        <div class="item-value-wrap">
                            <p class="value-title">Part Number</p>
                            <p class="value">{{ item.partNumber }}</p>
                        </div>
                        <div class="item-value-wrap">
                            <p class="value-title">Record ID#</p>
                            <p class="value">{{ item.id }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Select from "../../components/Select"
import config from "../../config"
import { nanoid } from "nanoid"

export default {
    data: function() {
        return {
            items: [],
            searchValue: "",
            isShowBody: true,
            selectedValue: "",
        }
    },
    components: {
        Select,
    },
    async mounted() {
        try {
            let { rid } = this.$route.params

            let response = await fetch(config.APIPath + "/masters", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                }
            })
            let product = await fetch(config.APIPath + `/product/${rid}`)

            response = await response.json()
            product = await product.json()

            this.searchValue = product.partNumber
            this.items = response
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        filteredItems() {
            let value = this.searchValue.toLowerCase()

            return this.items.filter(item => String(item.partNumber).toLowerCase().includes(value))
        }
    },
    methods: {
        handleClick() {
            this.isShowBody = true
        },
        async selectItem(item) {
            try {
                let { rid } = this.$route.params
                this.selectedValue = item
                this.isShowBody = false

                let response = await fetch(config.APIPath + `/masters/${rid}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.$store.state.auth.accessToken}`
                    },
                    body: JSON.stringify({ master: item.id })
                })

                response = await response.json()

                this.$store.commit("notifications/add", {
                    id: nanoid(),
                    text: "Product was synced successfully",
                    status: "success",
                })

            } catch (err) {
                console.error(err)
            }
        },
    },
}

</script>

<style lang="sass">

div.v-masters
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    padding-top: 15px

    div.select-wrap
        position: relative
        width: 100%
        max-width: 360px

        img.arrow
            position: absolute
            top: 50%
            right: 19px
            transform: translateY(-50%)
            max-width: 10px

        span.placeholder
            letter-spacing: 0.004em
            font-size: 16px
            line-height: 24px
            color: #939593
        
        div.value
            display: flex
            justify-content: space-between
            align-items: center
            width: 90%

        div.value-wrap
            // display: flex
            // justify-content: center
            // align-items: center
            // position: relative
            // display: flex
            // justify-content: space-between
            // align-items: center
            // padding: 0px 16px
            // border: 1px solid #939593
            // background: #fff
            // min-height: 50px
            // border-radius: 6px
            // cursor: pointer
            // width: 100%

            p.title
                text-align: center
                font-size: 22px
                font-style: italic
                margin: 0
                margin-bottom: 10px

        div.item-value-wrap
            p
                margin: 0

            p.value-title
                opacity: 0.5
                font-size: 15px

        div.body
            position: absolutetext
            top: 100%
            border: 1px solid #939593
            max-height: 300px
            overflow: auto
            width: 100%
            left: 0
            border-radius: 6px
            margin-top: 2px
            background: #fff
            z-index: 100

        // div.search-inp-wrap
        //     padding: 10px

        input.search-inp
            min-width: 100% !important
            outline: none
            font-size: 16px
            border-radius: 3px
            padding: 5px
            border: 1px solid rgba(0,0,0,.5)

        ul.list
            list-style: none
            margin: 0
            padding: 0

        li.list-item
            display: flex
            justify-content: space-between
            align-items: center
            padding: 10px 
            border-bottom: 1px solid #000
            cursor: pointer
            transition: 0.1s
            &:hover
                background: rgba(0,0,0,.1)
            &:last-child
                border-bottom: none

    p.info
        color: green
        font-size: 14px
        margin: 15px 0

    div.btn-wrap
        min-width: 360px
        
        div.btn
            width: 100%
            background: #d79b44
            color: #fff
            display: flex
            justify-content: center
            align-items: center
            min-height: 40px
            cursor: pointer
            margin-bottom: 20px
            border-radius: 3px
            text-transform: uppercase

</style>
