import Vue from 'vue'
import VueRouter from 'vue-router'
import Report from "../views/Report"
import SignIn from "../views/SignIn"
import Masters from "../views/Masters"
import SelectPopup from "../popups/SelectPopup.vue"

Vue.use(VueRouter)

const routes = [{
        path: '/masters/:rid',
        name: 'Masters',
        component: Masters,
    },

    {
        path: '/report/:customerId?',
        // name: 'Report',
        component: Report,
        children: [{
            path: 'select/:itemId',
            component: SelectPopup
        }],

    },
    {
        path: '/:customerId?',
        name: 'SignIn',
        component: SignIn,
    },
]

const router = new VueRouter({
    routes
})

export default router