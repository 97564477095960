<template>
    <div :class="`c-item ${itemState}`">
        <div class="item-part w20">
            <Checkbox
                :onChange="handleChange"
                :onClick="handleClick"
                :isPrevent="true"
                :isChecked="isChecked"
            />
            <span>{{ brandName }}</span>
        </div>
        <div class="item-part w20">
            {{ printerModel }}
        </div>
        <div class="item-part w5">
            {{ dpi }}
        </div>
        <div class="item-part w10">
            {{ quality }}
        </div>
        <div class="item-part w15">
            {{ partNumber }}
        </div>
        <div class="item-part w15">
            {{ oem }}
        </div>
        <div class="item-part w10">
            {{ msrp }}
        </div>
        <div class="item-part w10">
            {{ publishable }}
        </div>
        <div
            class="item-part w13 origin-price-wrap"
            v-if="isShowOriginalPrice"
        >
            {{ originalPrice }}
        </div>
        <div class="item-part w12 price-wrap">
            <input
                type="text"
                :class="`price-inp ${isActivePrice ? 'active' : ''}`"
                @focus="handleFocus"
                @blur="handleBlur"
                @keydown="handleKeydown"
                v-model="priceValue"
            >
            <img src="@/assets/exchange.svg" alt="exchange">
        </div>
    </div>
</template>

<script>
import Checkbox from "../../../components/Checkbox"

export default {
    props: {
        id: [String, Number],
        brandName: String,
        printerModel: [String, Number],
        dpi: [String, Number],
        quality: String,
        partNumber: [String, Number],
        oem: [String, Number],
        msrp: [String, Number],
        customerPrice: [String, Number],
        isChecked: Boolean,
        originalPrice: [String, Number],
        isShowOriginalPrice: Boolean,
        publishable: String,
        // handleSelect: Function,
        changePrice: Function,
    },
    data: function() {
        return {
            lastParentColor: "",
            isActivePrice: "",
            priceValue: this.isShowOriginalPrice ? this.customerPrice : this.originalPrice,
            startValue: "",
        }
    },
    components: {
        Checkbox,
    },
    computed: {
        priceState() {
            if(this.customerPrice != this.originalPrice)
                return "changed"

            return ""
        },
        itemState() {
            let currentPrice = +this.priceValue.substring(1)
            let originalPrice = +this.originalPrice.substring(1)

            if(currentPrice < originalPrice)
                return "reduced"
            else if (currentPrice > originalPrice)
                return "increased"

            return ""
        },
    },
    methods: {
        handleClick(e) {
            let { customerId } = this.$route.params

            if(customerId)
                this.$router.push(`/report/${customerId}/select/${this.id}`)
            else
                this.$router.push(`/report/select/${this.id}`)
        },
        handleChange(e) {
            let isChecked = e.currentTarget.checked

            if(!isChecked)
                this.$store.commit("unselectItem", this.id)
        },
        handleFocus(e) {
            this.startValue = this.priceValue

            this.isActivePrice = true
        },
        handleBlur(e) {
            this.isActivePrice = false
            let inp = e.currentTarget
            let value = +inp.value.substring(1)
            let currentPrice = +this.priceValue.substring(1)
            let originalPrice = +this.originalPrice.substring(1)

            if(!isNaN(value)) {
                inp.blur()
                setTimeout(() => {
                    this.changePrice(this.id, this.priceValue)

                    if(currentPrice !== originalPrice)
                        inp.parentElement.classList.add("changed")

                    inp.classList.remove("active")
                }, 100)
            }
        },
        handleKeydown(e) {
            let inp = e.currentTarget

            setTimeout(() => {
                let isDollar = this.priceValue.includes("$")
    
                if(!isDollar)
                    this.priceValue = "$"
            }, 0)
        }
    }
}
</script>

<style lang="sass">

div.c-item
    display: flex
    justify-content: space-between
    background: #fff
    margin-bottom: 2px
    transition: 0.2s

    &.increased
        background: #ffcccb
    &.reduced
        background: linear-gradient(0deg, rgba(9, 86, 59, 0.16), rgba(9, 86, 59, 0.16)), #FFFFFF

    div.item-part
        display: flex
        align-items: center
        padding: 5px
        border-right: 2px solid #EAF1F9
        padding: 14px 10px
        border-radius: 2px
        min-height: 50px
        position: relative
        letter-spacing: -0.01em
        font-size: 14px
        word-wrap: break-word
        word-break: break-word

    input.price-inp
        width: 100%
        outline: none
        border: none
        padding: 4px 5px
        letter-spacing: 0.004em
        font-size: 16px
        line-height: 24px
        border-radius: 6px
        background: rgba(0,0,0,0)
        border: 1px solid rgba(0,0,0,0)
        min-width: 100px
        &.active
            border: 1px solid #09563B
            background: #fff

    div.price-wrap
        position: relative
        img
            display: none
            position: absolute
            top: 10px
            right: 10px
            max-width: 10px
        &.changed
            img
                display: block

    div.origin-price-wrap
        font-size: 16px

</style>