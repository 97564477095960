<template>
    <div :class="`c-multi-select ${isSelected ? 'active' : ''}`">
        <div class="value-wrap" @click.stop="handleClick">
            <span class="placeholder" v-show="!isSelected">{{ placeholder }}</span>
            <div
                v-for="item in selectedValues"
                :key="item.id"
                class="selected-item"
                v-show="isSelected"
                @click.stop="removeItem(item.id)"
            >
                <span>{{ item.text }}</span>
            </div>
        </div>
        <div class="body" v-show="isShowBody" @click.stop>
            <div class="search-inp-wrap">
                <input type="text" class="search-inp" v-model="searchValue">
            </div>
            <ul class="list">
                <li
                    v-for="item in filteredItems"
                    :key="item.id"
                    class="list-item"
                >
                    <Checkbox
                        :value="item.id"
                        :onChange="selectItem"
                        :isChecked="!!selectedValues.find(selectedItem => selectedItem.id === item.id)"
                    />
                    <span class="value">{{ item.text }}</span>
                </li>
            </ul>
        </div>
        <img src="@/assets/down-arrow.svg" alt="arrow-down" class="arrow">
    </div>
</template>

<script>
import Checkbox from "../components/Checkbox"

export default {
    props: {
        items: Array,
        placeholder: String,
        selectedItems: Array,
        onSelect: Function,
        deleteItem: Function,
    },
    data: function() {
        return {
            selectedValues: [],
            searchValue: "",
            isShowBody: false,
        }
    },
    components: {
        Checkbox,
    },
    computed: {
        filteredItems() {
            return this.items.filter(item => item.text.toLowerCase().includes(this.searchValue.toLowerCase()))
        },
        isSelected() {
            return !!this.selectedValues.length  
        },
    },
    methods: {
        handleClick() {
            document.addEventListener("click", this.closeBody)
            this.isShowBody = !this.isShowBody
        },
        selectItem(e) {
            let inp = e.currentTarget
            let isChecked = inp.checked
            let id = inp.value
            let item = this.items.find(item => item.id == id)

            if(isChecked) {
                this.selectedValues.push(item)
                this.onSelect(this.selectedValues.map(item => item.text))
            } else {
                this.removeItem(id)
            }

        },
        closeBody() {
            this.isShowBody = false
        },
        removeItem(id) {
            let index = this.selectedValues.findIndex(item => item.id == id)

            if(index >= 0)
                this.selectedValues.splice(index, 1)

            this.deleteItem(this.selectedValues.map(item => item.text))
        },
    },
}

</script>

<style lang="sass">

div.c-multi-select
    width: 390px
    position: relative

    img.arrow
        position: absolute
        top: 50%
        right: 19px
        transform: translateY(-50%)
        max-width: 10px

    &.active div.value-wrap
        padding: 10px 10px 2px 10px

    span.placeholder
        letter-spacing: 0.004em
        font-size: 16px
        line-height: 24px
        color: #939593

    div.value-wrap
        padding: 12px 16px
        border: 1px solid #939593
        background: #fff
        min-height: 50px
        border-radius: 6px
        cursor: pointer

    div.selected-item
        padding: 5px 10px
        border-radius: 5px
        color: #fff
        background: #09563B
        display: inline-flex
        justify-content: space-between
        align-items: center
        margin-right: 5px
        margin-bottom: 5px
        font-size: 14px

    div.body
        position: absolute
        top: 100%
        border: 1px solid #939593
        max-height: 300px
        overflow: auto
        width: 100%
        left: 0
        border-radius: 6px
        margin-top: 2px
        background: #fff
        z-index: 100

    div.search-inp-wrap
        padding: 10px

    input.search-inp
        min-width: 100% !important

    ul.list
        list-style: none
        margin: 0
        padding: 0

    li.list-item
        display: flex
        justify-content: flex-start
        align-items: center
        padding: 10px 
        border-bottom: 1px solid #000
        &:last-child
            border-bottom: none 

</style>