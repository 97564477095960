export default {
    namespaced: true,
    state: () => ({
        notifications: [],
    }),
    mutations: {
        add(state, data) { // { id: string | number, text: string, status: "error" | "warning" | "success" }
            state.notifications.push(data)
            this.commit("notifications/remove")
        },
        remove(state) {
            setTimeout(() => state.notifications.shift(), 4000)
        },
    },
    actions: {},
}