<template>
    <div class="c-popup-wrap">
        <div class="popup select-popup">
            <p class="descr">Are you sure you selected {{ partNumber }}?</p>
            <div class="btns-wrap">
                <div class="btn yes" @click="selectItem">Yes</div>
                <div class="btn no" @click="notSelectItem">No</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    // props: {
    //     partNumber: [String, Number],
    // },
    data: function() {
        return {}
    },
    computed: {
        partNumber() {
            let { itemId } = this.$route.params

            return this.$store.state.items.find(item => item.id === +itemId).partNumber
        }
    },
    methods: {
        selectItem() {
            let { customerId } = this.$route.params
            let { itemId } = this.$route.params

            this.$store.commit("selectItem", +itemId)
            
            this.$router.push(`/report/${customerId}`)
        },
        notSelectItem() {
            let { customerId } = this.$route.params

            this.$router.push(`/report/${customerId}`)
        },
    },
}

</script>

<style lang="sass">

div.c-popup-wrap
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100%
    background: rgba(0,0,0,.6)
    z-index: 100

    div.select-popup
        padding: 25px
        border-radius: 5px
        background: #fff

    p.descr
        margin: 0
        margin-bottom: 20px
        font-size: 18px
        font-weight: 500

    div.btns-wrap
        display: flex
        justify-content: center
        align-items: center
        
        div.btn
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer
            padding: 5px
            min-height: 40px
            min-width: 100px
            color: #fff
            border-radius: 3px
            font-size: 15px

            &:first-child
                margin-right: 15px

            &.yes
                background: #28a745

            &.no
                background: #dc3545

</style>