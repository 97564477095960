<template>
    <label class="check-wrap">
        <input
            type="checkbox"
            @click="handleClick"
            @change="onChange"
            :checked="isChecked ? isChecked : false"
            :value="value ? value : ''"
        >
        <div class="castom-check">
            <img src="@/assets/check.svg" alt="check">
        </div>
    </label>
</template>

<script>

export default {
    props: {
        value: [String, Number],
        onChange: Function,
        onClick: Function,
        isPrevent: Boolean,
        isChecked: Boolean,
    },
    data: function() {
        return {}
    },
    methods: {
        handleClick(e) {
            let isChecked = e.currentTarget.checked

            if(this.isPrevent && isChecked) {
                e.preventDefault()
                if(this.onClick)
                    this.onClick()
            }
        }
    },
}

</script>

<style lang="sass">

label.check-wrap
    margin-right: 10px
    cursor: pointer

    input
        display: none
        &:checked ~
            div.castom-check
                background: #09563B
                img
                    display: block

    div.castom-check
        width: 20px
        height: 20px
        border: 1px solid #09563B
        background: #fff
        border-radius: 6px
        display: flex
        justify-content: center
        align-items: center

    img
        width: 70%
        display: none

</style>