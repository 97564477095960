<template>
  <div id="app">
    <router-view/>
    <transition-group name="fade">
        <Notification
            v-for="notification in notifications"
            :key="notification.id"
            :id="notification.id"
            :text="notification.text"
            :status="notification.status"
        />
    </transition-group>
    <CreateProduct v-show="popups.createProduct"/> 
  </div>
</template>

<script>
import Notification from "./components/Notification"
import CreateProduct from "./popups/CreateProduct"


export default {
    data: function () {
    return {};
  },
  components: {
    Notification,
    CreateProduct,
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.notifications;
        },
        popups() {
      return this.$store.state.popups
    }

    },
    
  beforeMount() {
    this.checkLogin();
  },
  updated() {
    this.checkLogin();
  },
  methods: {
    // TODO: Rewrite
    checkLogin() {
      let userData = JSON.parse(localStorage.getItem("data"));
      let ticket = localStorage.getItem("ticket");
      let currentPath = this.$router.currentRoute.path;
      let { customerId } = this.$route.params;

            if (!userData || !ticket) {
        if (currentPath != "/") {
          if (customerId) this.$router.push(`/${customerId}`);
          else this.$router.push(`/`);
        }
      } else {
        if (
          currentPath === "/" ||
          (currentPath.match(/\/[\d]/) && !currentPath.match(/\/report/)) && !currentPath.match(/\/masters/)
        ) {
          if (customerId) this.$router.push(`/report/${customerId}`);
          else this.$router.push("/report");
        }
        this.$store.commit("auth/setUserData", userData);
        this.$store.commit("auth/setToken", ticket);
      }

        },
    },
};

</script>


<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap')

html,
body
    margin: 0
    padding: 0
    background: #EAF1F9

*
    box-sizing: border-box
    font-family: "Open Sans", serif

div.container
    max-width: 1200px
    margin: 0 auto

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
    opacity: 0

</style>
