<template>
    <div :class="`c-select-wrap ${isSelected ? 'active' : ''}`">
        <div class="value-wrap" @click.stop="handleClick">
            <span class="placeholder" v-show="!isSelected">{{ placeholder }}</span>
            <div class="value">
                <span>{{ value }}</span>
            </div>
        </div>
        <div class="body" v-show="isShowBody" @click.stop>
            <div class="search-inp-wrap">
                <input type="text" class="search-inp" v-model="searchValue">
            </div>
            <ul class="list">
                <li
                    v-for="item in filteredItems"
                    :key="item.id"
                    class="list-item"
                    @click="selectItem(item)"
                >
                    <span class="value">{{ item.name }}</span>
                </li>
            </ul>
        </div>
        <img src="@/assets/down-arrow.svg" alt="arrow-down" class="arrow">
    </div>
</template>

<script>

export default {
    props: {
        items: Array,
        placeholder: String,
        selectedCustomer: [String, Number],
        selectedItems: Array,
        onSelect: Function,
        deleteItem: Function,
    },
    data: function() {
        return {
            // value: "",
            searchValue: "",
            isShowBody: false,
        }
    },
    computed: {
        filteredItems() {
            let value = this.searchValue.toLowerCase()

            return this.items.filter(item => item.name.toLowerCase().includes(value))
        },
        isSelected() {
            return !!this.value
        },
        value() {
            let item = this.items.find(item => item.id == this.selectedCustomer)
            
            if(item)
                return item.name
            else
                return ""
        },
    },
    methods: {
        handleClick() {
            document.addEventListener("click", this.closeBody)
            this.isShowBody = true
        },
        selectItem(item) {
            this.isShowBody = false
            this.searchValue = ""

            this.onSelect(item)
        },
        closeBody() {
            this.isShowBody = false
        },
    },
}

</script>

<style lang="sass">

div.c-select-wrap
    width: 390px
    position: relative

    img.arrow
        position: absolute
        top: 50%
        right: 19px
        transform: translateY(-50%)
        max-width: 10px

    span.placeholder
        letter-spacing: 0.004em
        font-size: 16px
        line-height: 24px
        color: #939593

    div.value-wrap
        display: flex
        justify-content: flex-start
        align-items: center
        padding: 0px 16px
        border: 1px solid #939593
        background: #fff
        min-height: 50px
        border-radius: 6px
        cursor: pointer

    div.body
        position: absolute
        top: 100%
        border: 1px solid #939593
        max-height: 300px
        overflow: auto
        width: 100%
        left: 0
        border-radius: 6px
        margin-top: 2px
        background: #fff
        z-index: 100

    div.search-inp-wrap
        padding: 10px

    input.search-inp
        min-width: 100% !important

    ul.list
        list-style: none
        margin: 0
        padding: 0

    li.list-item
        display: flex
        justify-content: flex-start
        align-items: center
        padding: 10px 
        border-bottom: 1px solid #000
        cursor: pointer
        &:last-child
            border-bottom: none 

</style>