<template>
    <div class="v-sign-in">
        <div class="container">
            <form class="sign-in-form" @submit.prevent="handleSubmit">
                <!-- <img src="@/assets/logo.svg" alt="logo" class="logo"> -->
                <div class="inp-wrap">
                    <p class="title">Email</p>
                    <input
                        type="email"
                        class="inp"
                        name="email"
                    >
                </div>
                <div class="inp-wrap">
                    <p class="title">Password</p>
                    <input
                        type="password"
                        class="inp"
                        name="password"
                    >
                </div>
                <div class="btn-wrap">
                    <button class="btn">Sign In</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {}
    },
    methods: {
        handleSubmit(e) {
            try {
                let data = {}
                let { customerId } = this.$route.params

                ;[...e.currentTarget.elements].forEach(item => {
                    if(item.name)
                        data[item.name] = item.value
                })

                if(customerId)
                    data.customerId = this.$route.params.customerId

                this.$store.dispatch("auth/signIn", data)
            } catch (err) {
                console.error(err)
            }
        }  
    },
}

</script>

<style lang="sass">

div.v-sign-in

    div.container
        min-height: 100vh
        display: flex
        justify-content: center
        align-items: center

    form.sign-in-form
        min-width: 400px

    div.inp-wrap
        margin-bottom: 20px
        p.title
            font-size: 14px
            margin: 0 0 5px 0
            color: #505050
    
    input.inp
        padding: 15px 8px
        font-size: 18px
        outline: none
        width: 100%
        border: 1px solid #505050

    div.btn-wrap
        display: flex
        justify-content: center

    button.btn
        font-size: 18px
        border: none
        width: 100%
        min-height: 56px
        color: #fff
        // box-shadow: 0 2px 6px rgb(000/16%)
        text-transform: uppercase
        outline: none
        cursor: pointer
        transition: .1s
        background: #000

</style>