import Vue from 'vue'
import Vuex from 'vuex'
import notifications from "./modules/notifications"
import auth from "./modules/auth"
import popups from "./modules/popups"
import config from "../config"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        items: [],
        selectedItems: [],
    },
    mutations: {},
    actions: {},
    modules: {
        notifications,
        auth,
        popups,
    },
    mutations: {
        setItems(state, data) {
            state.items = data
        },
        clearItems(state) {
            state.items = []
        },
        selectItem(state, id) {
            let item = state.items.find(item => item.id === id)

            state.selectedItems.push(item)
        },
        unselectItem(state, id) {
            let index = state.selectedItems.findIndex(item => item.id === id)

            state.selectedItems.splice(index, 1)
        },
        selectAllItems(state, items) {
            state.selectedItems = items
        },
        unselectAllItems(state) {
            state.selectedItems = []
        },
    },
    actions: {
        async loadItems({ commit, state }) {
            try {

                let response = await fetch(config.APIPath + "/products", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${state.auth.accessToken}`
                    },
                })

                if (response.status === 400)
                    throw new Error("Invalid Token")

                response = await response.json()

                commit("setItems", response.products)
            } catch (err) {
                console.error("ERRRORRR", err)
                commit("auth/signOut")
            }
        },
    },
})