export default {
    namespaced: true,
    state: () => ({
        createProduct: false,
    }),
    getters: {},
    mutations: {
        show(state, popupName) {
            state[popupName] = true
        },
        hide(state, popupName) {
            state[popupName] = false
        },
    },
    actions: {}
}