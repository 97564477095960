<template>
    <div :class="`c-notification ${status}`">
        <span class="tex">{{ text }}</span>
        <img
            src="@/assets/error.svg"
            alt="error"
            v-show="isError"
        >
        <img
            src="@/assets/warning.svg"
            alt="warning"
            v-show="isWarning"
        >
        <img
            src="@/assets/checked.svg"
            alt="success"
            v-show="isSuccess"
        >
    </div>
</template>

<script>

export default {
    props: {
        id: [String, Number],
        text: String,
        status: String, // error, success, warning
    },
    data: function() {
        return {}
    },
    computed: {
        isError() {
            return this.status === "error"
        },
        isWarning() {
            return this.status === "warning"
        },
        isSuccess() {
            return this.status === "success"
        },
    },
}

</script>

<style lang="sass">

div.c-notification
    display: inline-flex
    justify-content: space-between
    align-items: center
    border-radius: 5px
    position: fixed
    bottom: 15px
    right: 15px
    background: #fff
    padding: 25px
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2)
    min-width: 300px

    &.success
        border: 2px solid #a6f1cc

    &.error
        border: 2px solid #ffcccb

    &.warning
        border: 2px solid #ffffa1
    
    span.text
        font-size: 20px
    
    img
        max-width: 30px
        margin-left: 20px

</style>